<template>
    <div>
        <b-modal 
        id="tariffEdit" 
        title="Изменить тариф" 
        size="lg" 
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true" 
        @shown="tariffData">
            <b-row>
                <b-col cols="6">
                    <b-row>
                        <b-col>
                            <label for="">Подразделение</label>
                             <b-form-group>
                                <v-select
                                    label="name"
                                    v-model="tariff.division_id"
                                    :options="divisions"
                                    class="select-size-sm"
                                    :reduce="item => item.id"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label>Тип тарифа
                            </label>
                            <b-form-group>
                                <v-select
                                    label="name"
                                    v-model="tariff.type_tariff_id"
                                    :options="tariffTypes"
                                    class="select-size-sm"
                                    @input="setAllowances($event)"
                                    :reduce="tariffType => tariffType.id"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="">Наименование</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.name"/>
                            </b-form-group>    
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="">Цена подачи</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.delivery_price" type="number"/>
                            </b-form-group>    
                        </b-col>
                        <b-col>
                            <label for="">Минимальная цена</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.min_price" type="number"/>
                            </b-form-group>       
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="">Цена за 1км(город):</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.price_km_city" type="number"/>
                            </b-form-group>    
                        </b-col>
                        <b-col>
                            <label for="">Цена за 1км(межгород):</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.price_km_intercity" type="number"/>
                            </b-form-group>       
                        </b-col>
                         <b-col class="mt-1">
                            <label for="">Цена за 1 час:</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.price_hour" type="number"/>
                            </b-form-group>       
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="margin-top: 60px;">
                            <b-form-group label="Порядок">
                                <b-form-input size="sm" v-model="tariff.sort"/>
                            </b-form-group>    
                        </b-col>
                        <b-col>
                            <b-form-group label="Порог расстояния для рекламы цена:">
                                <b-form-input size="sm" v-model="tariff.price_advertising" type="number"/>
                            </b-form-group>
                        </b-col>
                        <b-col class="mt-2">
                            <b-form-group label="Минимальная сумма неустойки:">
                                <b-form-input size="sm" v-model="tariff.min_penalty"/>
                            </b-form-group>    
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="margin-top: 18px;">
                            <label for="">Цена заезда</label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.check_in_price" type="number"/>
                            </b-form-group>    
                        </b-col>
                        <b-col>
                            <label for="">Бесплатное ожидание (в минутах) </label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.free_waiting_of_client_in_minute" type="number"/>
                            </b-form-group>       
                        </b-col>
                        <b-col style="margin-top: 15px;">
                            <label for="">Радиус автоназначение </label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.radius_auto_assignment" type="number"/>
                            </b-form-group>       
                        </b-col>
                        <b-col class="mt-1">
                            <label for="">Округление цены </label>
                            <b-form-group>
                                <b-form-input size="sm" v-model="tariff.price_rounding" type="number"/>
                            </b-form-group>       
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="margin-top: 28px;">
                            <label class="d-flex">
                                <b-form-checkbox v-model="tariff.is_active" id="2"></b-form-checkbox>
                                <div style="user-select: none; font-size: 16px;">
                                    Блокирование (вкл или вылк блокирование тарифа)
                                </div>
                            </label>
                        </b-col>
                        <b-col style="margin-top: 28px;">
                            <label class="d-flex">
                                <b-form-checkbox v-model="tariff.auto_assignment" id="2"></b-form-checkbox>
                                <div style="user-select: none; font-size: 16px;">
                                    Включить автоматичиское назначение заказов по этому тариф
                                </div>
                            </label>
                        </b-col>
                        <b-col style="margin-top: 28px;">
                            <label class="d-flex">
                                <b-form-checkbox v-model="tariff.is_send_push_new_order" id="2"></b-form-checkbox>
                                <div style="user-select: none; font-size: 16px;">
                                    Отправить Push-уведомление водителям о новом заказе
                                </div>
                            </label>
                        </b-col>
                        <b-col style="margin-top: 28px;">
                            <label class="d-flex">
                                <b-form-checkbox v-model="tariff.is_working" id="2"></b-form-checkbox>
                                <div style="user-select: none; font-size: 16px;">
                                    Обновить в рабочую версию
                                </div>
                            </label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="6" class="mt-2">
                    <b-row>
                        <b-col cols="7">
                            <b-form-group style="border: 1px solid rgb(231, 227, 227); padding: 7px 12px; border-radius: 5px;">
                                <span><b>Надбавки:</b></span>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5">
                            <b-col>
                                <b-form-group style="border: 1px solid rgb(231, 227, 227); padding: 7px 12px; border-radius: 5px;">
                                    <span><b>Стоимость:</b></span>
                                </b-form-group>
                            </b-col>
                        </b-col>
                    </b-row>
                    
                    <template v-if="tariff.allowances">
                        <b-row v-for="(item, id) in tariff.allowances" :key="id">
                            <b-col cols="7">
                                <b-form-group style="border: 1px solid rgb(231, 227, 227); padding: 7px 12px; border-radius: 5px;">
                                    <span v-text="item.name"></span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="5">
                                <b-col>
                                    <b-form-group>
                                        <b-form-input v-model="item.price" type="number" placeholder="Цена"/>
                                    </b-form-group>
                                </b-col>
                            </b-col>
                        </b-row>
                        <b-col cols="20">
                            <label for="">Описание тарифа:</label>
                            <b-form-textarea placeholder="Описание тарифа..." v-model="tariff.description" />
                        </b-col>
                        <b-col cols="20">
                            <label for="">Причина обновления в активную версию тарифа:</label>
                            <b-form-textarea placeholder="Причина обновления в активную версию тарифа..." v-model="tariff.reason_active_version" />
                        </b-col>  
                    </template>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editTariff">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        ToastificationContent
    },
    props: ['divisions', 'tariffTypes', 'id'],
    data(){
        return {
            tariff: {},
            submitButtonDisabled: false,
        }
    },
    methods: {
        setAllowances(id) {
            this.tariff.allowances = this.tariffTypes.find((tariffType) => tariffType.id === id).allowances
        },
        tariffData(){
            this.$http
                .get(`base-tariffs/${this.id}/edit`)
                .then(res => {
                    this.tariff = res.data
                    this.tariff.is_active = this.tariff.is_active == 1 ? true : false
                    this.tariff.auto_assignment = this.tariff.auto_assignment == 1 ? true : false
                    this.tariff.is_send_push_new_order = this.tariff.is_send_push_new_order == 1 ? true : false
                    this.tariff.is_working = this.tariff.is_working == 1 ? true : false
                })
        },
        editTariff(){
            this.submitButtonDisabled = true
            const tariff = JSON.parse(JSON.stringify(this.tariff))
            tariff.is_active = tariff.is_active == true ? 1 : 0
            tariff.auto_assignment = tariff.auto_assignment == true ? 1 : 0
            tariff.is_send_push_new_order = tariff.is_send_push_new_order == true ? 1 : 0
            tariff.is_working = tariff.is_working == true ? 1 : 0
            tariff.allowances = JSON.stringify(tariff.allowances)
            console.log(tariff);
            this.$http
                .patch(`base-tariffs/${this.id}`, tariff)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.btnCancel()
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })

        },        
        btnCancel(){
            this.$bvModal.hide('tariffEdit')
            this.tariff = {}
        },

    },
    
}
</script>
